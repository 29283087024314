import { ActionType } from "typesafe-actions";

import * as BomAction from "./bomActions";

type Actions = typeof BomAction;

export type BomActions = ActionType<Actions>;


export * from "./bomActions";
export * from "./bomReducer";
export * from "./defaultState";
