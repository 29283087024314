import { ActionType } from "typesafe-actions";

import * as EnquiryActions from "./enquiry.actions";
import * as CommentActions from "../comments/comment.actions";
import { EnquiryProjectActions } from "./project";
import { EnquiryOrfActions } from "./enquiry-orf";
import { EnquiryQrfActions } from "./enquiry-qrf";
import { EnquiryMrfActions } from "./enquiry-mrf";
import { EnquiryCheckListActions } from "./enquiry-checklist";
import { EnquiryDocumentsActions } from "./enquiry-documents";
import { EnquiryEmailsActions } from "./enquiry-emails";
import { EnquiryHistoryActions } from "./enquiry-history";

type Actions = typeof EnquiryActions &
  EnquiryProjectActions &
  EnquiryOrfActions &
  EnquiryQrfActions &
  EnquiryMrfActions &
  EnquiryCheckListActions &
  EnquiryDocumentsActions &
  EnquiryEmailsActions &
  EnquiryHistoryActions &
  typeof CommentActions;

export type EnquiryActionsType = ActionType<Actions>;

export * from "./enquiry-checklist";
export * from "./enquiry-mrf";
export * from "./enquiry-orf";
export * from "./enquiry-qrf";
export * from "./project";
export * from "./enquiry-documents";
