import { IEnquiryCheckList } from "./enquiry-checklist.types";

export const defaultEnquiryCheckList: IEnquiryCheckList = {
  enquiry_checklist_id: null,
  enquiry_checklist_uuid: null,
  type_name: "",
  type_category: "checkbox",
  enquiry_no: null,
  description: null,
  comment: null,
  attachment: null,
  is_check: 0,
  created_by_uuid: null,
};
