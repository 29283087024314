import { AnyAction } from "redux";
import { ThunkAction } from "redux-thunk";
import { action } from "typesafe-actions";
import { api } from "../../api/api";
import { IQueryParams } from "../common/common.types";
import { getSearchQuery } from "../common/helpers";
import { IStoreState } from "../initialStoreState";
import {
  saveLoaderCompleted,
  saveLoaderProgress,
  showMessage,
} from "../messages/messagesActions";
import { IMrpAllocation, IMrpAllocationsState } from "./mrp-allocations.types";
import {
  SERVER_GET_MRP_ALLOCATION_ROUTE,
  SERVER_UPSERT_MRP_ALLOCATION_ROUTE,
} from "./mrp-allocations.constants";

export const FETCH_MRP_ALLOCATION_LIST_PROGRESS =
  "FETCH_MRP_ALLOCATION_LIST_PROGRESS";
export const FETCH_MRP_ALLOCATION_LIST_SUCCESS =
  "FETCH_MRP_ALLOCATION_LIST_SUCCESS";
export const FETCH_MRP_ALLOCATION_LIST_FAILED =
  "FETCH_MRP_ALLOCATION_LIST_FAILED";

export const fetchMrpAllocationListProgress = () =>
  action(FETCH_MRP_ALLOCATION_LIST_PROGRESS);

export const fetchMrpAllocationListSuccess = (
  data: IMrpAllocationsState["mrp_allocations_list"]["data"],
  totalRecords: number,
) => action(FETCH_MRP_ALLOCATION_LIST_SUCCESS, { data, totalRecords });
export const fetchMrpAllocationListFailed = () =>
  action(FETCH_MRP_ALLOCATION_LIST_FAILED);

export const fetchMrpAllocationListAsync =
  (queryParams: IQueryParams): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      const searchQuery = getSearchQuery(queryParams);
      let finalUrl = `${SERVER_GET_MRP_ALLOCATION_ROUTE}${searchQuery}`;
      dispatch(fetchMrpAllocationListProgress());
      const res = await api.get(finalUrl);
      const data: IMrpAllocationsState["mrp_allocations_list"]["data"] =
        res.data.data;

      const totalRecords = res.data.totalRecords;

      dispatch(fetchMrpAllocationListSuccess(data, totalRecords));
    } catch (err: any) {
      dispatch(fetchMrpAllocationListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    }
  };

export const fetchMrpAllocationApprovalsListAsync =
  (queryParams: IQueryParams): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      const searchQuery = getSearchQuery(queryParams);
      let finalUrl = `/approval/get-approval${searchQuery}&table_name=latest_mrp_allocation&status=REQUESTED`;
      dispatch(fetchMrpAllocationListProgress());
      const res = await api.get(finalUrl);
      const data: IMrpAllocationsState["mrp_allocations_list"]["data"] =
        res.data.data;

      const totalRecords = res.data.totalRecords;

      dispatch(fetchMrpAllocationListSuccess(data, totalRecords));
    } catch (err: any) {
      dispatch(fetchMrpAllocationListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    }
  };

export const FETCH_MRP_ALLOCATION_PROGRESS = "FETCH_MRP_ALLOCATION_PROGRESS";
export const FETCH_MRP_ALLOCATION_SUCCESS = "FETCH_MRP_ALLOCATION_SUCCESS";
export const FETCH_MRP_ALLOCATION_FAILED = "FETCH_MRP_ALLOCATION_FAILED";

export const fetchMrpAllocationProgress = () =>
  action(FETCH_MRP_ALLOCATION_PROGRESS);
export const fetchMrpAllocationSuccess = (data: IMrpAllocation) =>
  action(FETCH_MRP_ALLOCATION_SUCCESS, { data });
export const fetchMrpAllocationFailed = (errorMessage: string) =>
  action(FETCH_MRP_ALLOCATION_FAILED, { errorMessage });

export const fetchSingleMrpAllocationAsync =
  (uuid: string): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchMrpAllocationProgress());
      const res = await api.get(
        `${SERVER_GET_MRP_ALLOCATION_ROUTE}?mrp_allocation_uuid=${uuid}&stock_info=TRUE`,
      );
      const data: IMrpAllocation[] = res.data.data;
      if (data.length > 0) {
        dispatch(fetchMrpAllocationSuccess(data[0]));
      } else {
        dispatch(
          fetchMrpAllocationFailed("Oops! We couldn't find any records."),
        );
      }
    } catch (err: any) {
      dispatch(fetchMrpAllocationFailed("Something went to be wrong!"));
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    }
  };

export const upsertSingleMrpAllocationAsync =
  (
    data: IMrpAllocation,
    onCallback: (isSuccess: boolean, deviation?: IMrpAllocation) => void,
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      const { remark, create_ts, insert_ts, rev_no, ...payload } = data;

      dispatch(saveLoaderProgress());
      const res = await api.post(SERVER_UPSERT_MRP_ALLOCATION_ROUTE, payload);
      let message = "MRP Allocation saved successfully!";
      onCallback(true, res.data.data);
      dispatch(
        showMessage({
          type: "success",
          displayAs: "snackbar",
          message: message,
        }),
      );
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

export const upsertRequestMrpAsync =
  (
    uuid: string,
    onCallback: (isSuccess: boolean) => void,
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());
      await api.post("/planning/upsert-mrp-from-routing", {
        routing_uuid: uuid,
      });
      let message = "MRP requested successfully!";
      onCallback(true);
      dispatch(
        showMessage({
          type: "success",
          displayAs: "snackbar",
          message: message,
        }),
      );
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

export const CLEAR_MRP_ALLOCATION = "CLEAR_MRP_ALLOCATION";
export const CLEAR_MRP_ALLOCATION_STATE = "CLEAR_MRP_ALLOCATION_STATE";
export const clearSingleMrpAllocationSync = () => action(CLEAR_MRP_ALLOCATION);
export const clearMrpAllocationsStateSync = () =>
  action(CLEAR_MRP_ALLOCATION_STATE);
