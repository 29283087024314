import { ActionType } from "typesafe-actions";
import * as Actions from "./orderProcessing.actions";

type Actions = typeof Actions;

export type OrderProcessingActions = ActionType<Actions>;

export * from "./defaultState";
export * from "./orderProcessing.actions";
export * from "./orderProcessing.types";
