import { AnyAction } from "redux";
import { ThunkAction } from "redux-thunk";
import { action } from "typesafe-actions";
import { api } from "../../api/api";
import { IQueryParams } from "../common/common.types";
import { getSearchQuery } from "../common/helpers";
import { IStoreState } from "../initialStoreState";
import {
  saveLoaderCompleted,
  saveLoaderProgress,
  showMessage,
} from "../messages/messagesActions";
import {
  SERVER_GET_PURCHASE_REQUISITION_ROUTE,
  SERVER_UPSERT_PURCHASE_REQUISITION_ROUTE,
} from "./purchase-requisition.routes";
import { IPurchaseRequisition } from "./purchase-requisition.types";

export const FETCH_PURCHASE_REQUISITION_LIST_PROGRESS =
  "FETCH_PURCHASE_REQUISITION_LIST_PROGRESS";
export const FETCH_PURCHASE_REQUISITION_LIST_SUCCESS =
  "FETCH_PURCHASE_REQUISITION_LIST_SUCCESS";
export const FETCH_PURCHASE_REQUISITION_LIST_FAILED =
  "FETCH_PURCHASE_REQUISITION_LIST_FAILED";

export const fetchPurchaseRequisitionListProgress = () =>
  action(FETCH_PURCHASE_REQUISITION_LIST_PROGRESS);

export const fetchPurchaseRequisitionListSuccess = (
  data: IPurchaseRequisition[],
  totalRecords: number,
) => action(FETCH_PURCHASE_REQUISITION_LIST_SUCCESS, { data, totalRecords });
export const fetchPurchaseRequisitionListFailed = () =>
  action(FETCH_PURCHASE_REQUISITION_LIST_FAILED);

export const fetchPurchaseRequisitionListAsync =
  (queryParams: IQueryParams): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      const searchQuery = getSearchQuery(queryParams);
      dispatch(fetchPurchaseRequisitionListProgress());
      const res = await api.get(
        `${SERVER_GET_PURCHASE_REQUISITION_ROUTE}${searchQuery}`,
      );
      const data: IPurchaseRequisition[] = res.data.data;

      const totalRecords = res.data.totalRecords;

      dispatch(fetchPurchaseRequisitionListSuccess(data, totalRecords));
    } catch (err: any) {
      dispatch(fetchPurchaseRequisitionListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    }
  };

export const FETCH_PURCHASE_REQUISITION_PROGRESS =
  "FETCH_PURCHASE_REQUISITION_PROGRESS";
export const FETCH_PURCHASE_REQUISITION_SUCCESS =
  "FETCH_PURCHASE_REQUISITION_SUCCESS";
export const FETCH_PURCHASE_REQUISITION_FAILED =
  "FETCH_PURCHASE_REQUISITION_FAILED";

export const fetchPurchaseRequisitionProgress = () =>
  action(FETCH_PURCHASE_REQUISITION_PROGRESS);
export const fetchPurchaseRequisitionSuccess = (data: IPurchaseRequisition) =>
  action(FETCH_PURCHASE_REQUISITION_SUCCESS, { data });
export const fetchPurchaseRequisitionFailed = (errorMessage: string) =>
  action(FETCH_PURCHASE_REQUISITION_FAILED, { errorMessage });

export const fetchSinglePurchaseRequisitionAsync =
  (uuid: string): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchPurchaseRequisitionProgress());
      const res = await api.get(
        `${SERVER_GET_PURCHASE_REQUISITION_ROUTE}?purchase_requisition_uuid=${uuid}`,
      );
      const data: IPurchaseRequisition[] = res.data.data;
      if (data.length > 0) {
        dispatch(fetchPurchaseRequisitionSuccess(data[0]));
      } else {
        dispatch(
          fetchPurchaseRequisitionFailed("Oops! We couldn't find any records."),
        );
      }
    } catch (err: any) {
      dispatch(fetchPurchaseRequisitionFailed("Something went to be wrong!"));
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    }
  };

export const upsertSinglePurchaseRequisitionAsync =
  (
    data: IPurchaseRequisition,
    onCallback: (isSuccess: boolean, deviation?: IPurchaseRequisition) => void,
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      const { create_ts, insert_ts, ...payload } = data;

      dispatch(saveLoaderProgress());
      const res = await api.post(
        SERVER_UPSERT_PURCHASE_REQUISITION_ROUTE,
        payload,
      );
      console.log("gt");
      let message = "MRP Allocation saved successfully!";
      onCallback(true, res.data.data);
      dispatch(
        showMessage({
          type: "success",
          displayAs: "snackbar",
          message: message,
        }),
      );
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

// ********************** Fetch Approval BOM List********************** //
export const FETCH_APPROVAL_REQUISITION_LIST_PROGRESS =
  "FETCH_APPROVAL_REQUISITION_LIST_PROGRESS";
export const FETCH_APPROVAL_REQUISITION_LIST_SUCCESS =
  "FETCH_APPROVAL_REQUISITION_LIST_SUCCESS";
export const FETCH_APPROVAL_REQUISITION_LIST_FAILED =
  "FETCH_APPROVAL_REQUISITION_LIST_FAILED";

export const fetchApprovalPurchaseRequisitionListProgress = () =>
  action(FETCH_APPROVAL_REQUISITION_LIST_PROGRESS);
export const fetchApprovalPurchaseRequisitionListSuccess = (
  data: IPurchaseRequisition[],
  totalRecords: number,
) => action(FETCH_APPROVAL_REQUISITION_LIST_SUCCESS, { data, totalRecords });
export const fetchApprovalPurchaseRequisitionListFailed = () =>
  action(FETCH_APPROVAL_REQUISITION_LIST_FAILED);

export const fetchApprovalPurchaseRequisitionListAsync =
  (queryParams: IQueryParams): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      const searchQuery = getSearchQuery(queryParams);

      dispatch(fetchApprovalPurchaseRequisitionListProgress());
      const res = await api.get(
        `/approval/get-approval?table_name=latest_purchase_requisition&status=REQUESTED&${searchQuery}`,
      );
      const data: IPurchaseRequisition[] = res.data.data;

      const totalRecords = res.data.totalRecords;
      dispatch(fetchApprovalPurchaseRequisitionListSuccess(data, totalRecords));
    } catch (err: any) {
      dispatch(fetchApprovalPurchaseRequisitionListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    }
  };

export const CLEAR_PURCHASE_REQUISITION = "CLEAR_PURCHASE_REQUISITION";
export const CLEAR_PURCHASE_REQUISITION_STATE =
  "CLEAR_PURCHASE_REQUISITION_STATE";
export const clearSinglePurchaseRequisitionSync = () =>
  action(CLEAR_PURCHASE_REQUISITION);
export const clearPurchaseRequisitionsStateSync = () =>
  action(CLEAR_PURCHASE_REQUISITION_STATE);
