import { AnyAction } from "redux";
import { ThunkAction } from "redux-thunk";
import { action } from "typesafe-actions";
import { api } from "../../api/api";
import { IQueryParams } from "../common/common.types";
import { getSearchQuery } from "../common/helpers";
import { IStoreState } from "../initialStoreState";
import {
  saveLoaderCompleted,
  saveLoaderProgress,
  showMessage,
} from "../messages/messagesActions";
import { IBom, IBomItem, IBomProduct, IBomState } from "./bom.types";
import { uploadFile } from "../../helpers/uploadFile";

export const FETCH_BOM_LIST_PROGRESS = "FETCH_BOM_LIST_PROGRESS";
export const FETCH_BOM_LIST_SUCCESS = "FETCH_BOM_LIST_SUCCESS";
export const FETCH_BOM_LIST_FAILED = "FETCH_BOM_LIST_FAILED";

export const fetchBomListProgress = () => action(FETCH_BOM_LIST_PROGRESS);

export const fetchBomListSuccess = (
  data: IBomState["list"]["data"],
  totalRecords: number,
) => action(FETCH_BOM_LIST_SUCCESS, { data, totalRecords });
export const fetchBomListFailed = () => action(FETCH_BOM_LIST_FAILED);

export const fetchBomListAsync =
  (queryParams: IQueryParams): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      const searchQuery = getSearchQuery(queryParams);
      dispatch(fetchBomListProgress());
      const res = await api.get(`/bom/get-bom${searchQuery}`);
      const data: IBomState["list"]["data"] = res.data.data;

      const totalRecords = res.data.totalRecords;

      dispatch(fetchBomListSuccess(data, totalRecords));
    } catch (err: any) {
      dispatch(fetchBomListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    }
  };

export const FETCH_BOM_PROGRESS = "FETCH_BOM_PROGRESS";
export const FETCH_BOM_SUCCESS = "FETCH_BOM_SUCCESS";
export const FETCH_BOM_FAILED = "FETCH_BOM_FAILED";

export const fetchBomProgress = () => action(FETCH_BOM_PROGRESS);
export const fetchBomSuccess = (data: IBom) =>
  action(FETCH_BOM_SUCCESS, { data });
export const fetchBomFailed = (errorMessage: string) =>
  action(FETCH_BOM_FAILED, { errorMessage });

export const fetchBomAsync =
  (uuid: string): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchBomProgress());
      const res = await api.get(`/bom/get-bom?bom_uuid=${uuid}`);
      const data: IBom[] = res.data.data;
      if (data.length > 0) {
        dispatch(fetchBomSuccess(data[0]));
      } else {
        dispatch(fetchBomFailed("Oops! We couldn't find any records."));
      }
    } catch (err: any) {
      dispatch(fetchBomFailed("Something went to be wrong!"));
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    }
  };

export const upsertBomAsync =
  (
    data: IBom,
    file: any,
    onCallback: (isSuccess: boolean, bom?: IBom) => void,
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      let {
        create_ts,
        insert_ts,
        approval_status,
        approval_uuid,
        is_user_approver,
        requested_by_uuid,
        remark,
        rev_no,
        ...payload
      } = data;

      let path = "";
      if (file) {
        const asPayload = {
          series: data.series,
        };
        path = await uploadFile(
          file,
          "BOM",
          data.product_code || "",
          asPayload,
        );
        payload.excel_link = path;
      }

      dispatch(saveLoaderProgress());
      const res = await api.post("/bom/upsert-bom", payload);
      let message = "Bom saved successfully!";
      onCallback(true, res.data.data);
      dispatch(
        showMessage({
          type: "success",
          displayAs: "snackbar",
          message: message,
        }),
      );
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

// export const upsertRequestBomAsync =
//   (
//     enquiryNo: string,
//     onCallback: (isSuccess: boolean) => void,
//   ): ThunkAction<void, IStoreState, {}, AnyAction> =>
//     async (dispatch, getState) => {
//       try {
//         dispatch(saveLoaderProgress());
//         await api.post("/bom/upsert-request-bom", {
//           enquiry_no: enquiryNo,
//           status: "BOM_REQUESTED",
//         });
//         let message = "Bom requested successfully!";
//         onCallback(true);
//         dispatch(
//           showMessage({
//             type: "success",
//             displayAs: "snackbar",
//             message: message,
//           }),
//         );
//       } catch (err: any) {
//         onCallback(false);
//         dispatch(
//           showMessage({
//             type: "error",
//             message: err.response.data.message,
//             displayAs: "snackbar",
//           }),
//         );
//       } finally {
//         dispatch(saveLoaderCompleted());
//       }
//     };

export const upsertRequestRoutingAsync =
  (
    bomUuid: string,
    onCallback: (isSuccess: boolean) => void,
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());
      await api.post("/planning/upsert-routing-from-bom", {
        bom_uuid: bomUuid,
      });
      let message = "Routing requested successfully!";
      onCallback(true);
      dispatch(
        showMessage({
          type: "success",
          displayAs: "snackbar",
          message: message,
        }),
      );
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

// ********************** Fetch Approval BOM List********************** //
export const FETCH_APPROVAL_BOM_LIST_PROGRESS =
  "FETCH_APPROVAL_BOM_LIST_PROGRESS";
export const FETCH_APPROVAL_BOM_LIST_SUCCESS =
  "FETCH_APPROVAL_BOM_LIST_SUCCESS";
export const FETCH_APPROVAL_BOM_LIST_FAILED = "FETCH_APPROVAL_BOM_LIST_FAILED";

export const fetchApprovalBomListProgress = () =>
  action(FETCH_APPROVAL_BOM_LIST_PROGRESS);
export const fetchApprovalBomListSuccess = (
  data: IBom[],
  totalRecords: number,
) => action(FETCH_APPROVAL_BOM_LIST_SUCCESS, { data, totalRecords });
export const fetchApprovalBomListFailed = () =>
  action(FETCH_APPROVAL_BOM_LIST_FAILED);

export const fetchApprovalBomListAsync =
  (queryParams: IQueryParams): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      const searchQuery = getSearchQuery(queryParams);

      dispatch(fetchApprovalBomListProgress());
      const res = await api.get(
        `/approval/get-approval?table_name=latest_bom&status=REQUESTED&${searchQuery}`,
      );
      const data: IBom[] = res.data.data;

      const totalRecords = res.data.totalRecords;
      dispatch(fetchApprovalBomListSuccess(data, totalRecords));
    } catch (err: any) {
      dispatch(fetchApprovalBomListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    }
  };

// ********************** Upsert Bom Item ********************* //
export const upsertBomItemsAsync =
  (
    data: IBomItem,
    onCallback: (isSuccess: boolean, product?: IBomItem) => void,
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());
      const { create_ts, insert_ts, ...rest } = data;

      const res = await api.post("/bom/upsert-bom-item", rest);
      dispatch(
        showMessage({
          type: "success",
          message: "Item save successfully!",
          displayAs: "snackbar",
        }),
      );
      onCallback(true, res.data.data);
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

export const FETCH_BOM_ITEM_PROGRESS = "FETCH_BOM_ITEM_PROGRESS";
export const FETCH_BOM_ITEM_SUCCESS = "FETCH_BOM_ITEM_SUCCESS";
export const FETCH_BOM_ITEM_FAILED = "FETCH_BOM_ITEM_FAILED";

export const fetchBomItemProgress = () => action(FETCH_BOM_ITEM_PROGRESS);
export const fetchBomItemSuccess = (data: IBomItem) =>
  action(FETCH_BOM_ITEM_SUCCESS, { data });
export const fetchBomItemFailed = (errorMessage: string) =>
  action(FETCH_BOM_ITEM_FAILED, { errorMessage });

export const fetchBomItemAsync =
  (queryParams: IQueryParams): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      const searchQuery = getSearchQuery(queryParams);

      dispatch(fetchBomItemProgress());
      const res = await api.get(
        `/approval/get-approval?table_name=latest_bom&status=REQUESTED&${searchQuery}`,
      );
      const data: IBomItem[] = res.data.data;
      if (data.length > 0) {
        dispatch(fetchBomItemSuccess(data[0]));
      } else {
        dispatch(fetchBomItemFailed("Oops! We couldn't find any records."));
      }
    } catch (err: any) {
      dispatch(fetchBomFailed("Something went to be wrong!"));
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    }
  };

// upset bom item for hierarchy
export const upsertBomItemsHierarchyAsync =
  (
    bomUUID: string,
    onCallback: (isSuccess: boolean, data?: IBomProduct) => void,
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      // dispatch(saveLoaderProgress());

      const res = await api.post("/bom/bulk-insert-bom-item", {
        bom_uuid: bomUUID,
      });
      dispatch(
        showMessage({
          type: "success",
          message: "Item save successfully!",
          displayAs: "snackbar",
        }),
      );
      onCallback(true, res.data.data);
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

export const CLEAR_BOM = "CLEAR_BOM";
export const CLEAR_BOM_STATE = "CLEAR_BOM_STATE";
export const clearBom = () => action(CLEAR_BOM);
export const clearBomState = () => action(CLEAR_BOM_STATE);
