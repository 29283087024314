import { ActionType } from "typesafe-actions";

import * as Action from "./routingActions";

type Actions = typeof Action;

export type RoutingActions = ActionType<Actions>;

export * from "./routingActions";
export * from "./routingReducer";
export * from "./defaultState";
