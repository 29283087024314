import { ActionType } from "typesafe-actions";

import * as DrawingAction from "./drawing-uploads.actions";

type Actions = typeof DrawingAction;

export type DrawingActions = ActionType<Actions>;

export * from "./drawing-uploads.types";
export * from "./drawing-uploads.actions";
export * from "./drawing-uploads.reducer";
export * from "./drawing-uploads.state";
