import { ActionType } from "typesafe-actions";

import * as BomAction from "./masterBomActions";

type Actions = typeof BomAction;

export type MasterBomActions = ActionType<Actions>;

export * from "./masterBomActions";
export * from "./masterBomReducer";
export * from "./defaultState";
