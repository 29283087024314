import { ActionType } from "typesafe-actions";

import * as DeviationAction from "./deviationActions";

type Actions = typeof DeviationAction;

export type DeviationActions = ActionType<Actions>;


export * from "./deviation.types";
export * from "./deviationActions";
export * from "./deviationReducer";
export * from "./defaultState";
